<template>
  <div v-if="dialog" class="popup_overlay">
  <div class="custom_dialog bg-white" style="width:890px;max-height:800px;">
    <div class="cust_card">
      <div class=" rounded px-5 py-4 h-full bg-primary text-white heading-2 font-bold flex items-center justify-between">
        <div>
          <p class="">{{this.componentMasterId > 0 ? 'Edit Component' : 'Add Component'}}</p>
        </div>
        <div>
          <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="closeDetails(false)">Discard</button>
          <button class="list_top_card_btn font-bold  bg-success cta-text text-white py-2 px-4 rounded-3xl" @click="addComponent()">Save</button>
        </div>  
      </div>
      <div class="pb-4" style="max-height: 700px!important;overflow-y: auto;min-height: 300px;">
        <div class="p-2 grid grid-cols-12">
          <div class="box col-span-12 p-2">
            <p class="label_css">Component Name</p>
            <input
            :class="compNameErr ? 'border border-error' : 'border border-gray-500 focus:border-gray-800'"
            class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 " 
            placeholder="Component Name"
            @keyup="changeUnderScore(componentDetail.componentName, 'cName', '0')"
            v-model="componentDetail.componentName"
            >
            <p class="text-error" v-if="compNameErr">Component Name is Required</p>
          </div>
        </div>
        <div class="p-2 pt-0 grid grid-cols-12" v-for="(data, index) in componentDetail.componentFieldList" :key="index" >
          <div class="box col-span-6 p-2">
            <!-- <div class="mb-4">
              <div class="">
                <Dropdown 
                :inputext="data.fieldDataType" 
                :inputId="`searchwp-project${index}`"
                :lableText="'Select Field'"
                :placholderText="`Select Field`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :keyName="'fieldDataType'"
                :listId="`fielId${index}`"
                :items="inputFieldList"
                @inputChangeAction="selectedStatus1"
                @selectNewForVal="selectStatus"
                />
              </div>
            </div> -->
            <div class="bg-white rounded">
              <div class="w-full">
                <div class="relative" id="listID">
                  <p class="label_css">Select Field</p>
                  <div class="py-1 p-2 flex border border-gray-500 bg-white shadow rounded-md " @click.stop="showPopupBranch(index, data)" :class="data.fieldErr ? 'border border-error' :''">
                    <span class="place-self-center flex">
                      <div class="flex flex-auto flex-wrap" >
                        <input @focus="focus()" placeholder="Select Field" @input="searchValue(data.selFieldname)" v-model="data.selFieldname"
                        class="w-96 h-9 cursor-pointer  focus:outline-none heading-4 text-gray-900 bg-white focus:ring-blue-500 focus:border-blue-500">
                      </div>
                      <p v-if="displayBranch" class="self-center"><i class="fas fa-chevron-up"></i></p>
                      <span v-if="!displayBranch" class="self-center"><i class="fas fa-chevron-down"></i></span>
                    </span>
                  </div>
                  <div id="listing" class="flex-col w-full max-h-64 h-auto bg-white overflow-auto border border-gray-500 shadow-lg absolute z-50" style="" v-if="data.isOpenList && shortedList.length > 0" v-click-outside="hidePopupBranch">
                    <div v-for="(cell, indexA) in shortedList" :key="indexA" class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-2 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1s"
                    :class="data.fieldMasterId === cell.fieldMasterId ? 'bg-blue-100' : '' "
                    @click="selectName(cell, index, indexA)">
                      <div class=" pl-2">
                        {{ cell.fieldName }}
                      </div>
                    </div>
                  </div>
                  <div class="flex-col w-full max-h-64 h-auto bg-white overflow-auto border border-gray-500 shadow-lg absolute z-50" style="" v-if="data.isOpenList && shortedList.length === 0" v-click-outside="hidePopupBranch">
                    <div class="flex shadow hover:bg-gray-100 items-center  cursor-pointer p-3 pl-2  border-b hover:bg-teal-100 border-transparent border-l-2 relative hover:border-teal-100 heading-4 text-text1s">
                      <div class=" pl-2">
                        No record found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="text-error" v-if="data.fieldErr">Field Value is Required</p>
            </div>
          </div>
          <div class="box p-2" :class="componentDetail.componentFieldList.length === 1 ? 'col-span-6' : 'col-span-5'">
            <p class="label_css">Display Name</p>
            <input
            @keyup="changeUnderScore(data.fieldName, 'dName', index)"
            :class="data.displayErr ? 'border border-error' : 'border border-gray-500 focus:border-gray-800'"
            class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4" 
            placeholder="Display Name"
            v-model="data.fieldName"
            >
            <p class="text-error" v-if="data.displayErr">Display Name is Required</p>
          </div>
          <div class="box col-span-1 self-center mt-4 pl-2" v-if="componentDetail.componentFieldList.length > 1">
            <button v-if="componentDetail.componentFieldList.length > 1" class=" rounded-full h-7 w-7 bg-red-500  mr-1"
              @click="removeItemDetail(index)"
              >
              <i class="fas fa-minus text-white heading-3 pt-1"></i>
            </button>
          </div>
        </div>
        <div class="p-2 pt-0 flex pb-2">
          <p class=" text-blue-500 pl-2 heading-5 cursor-pointer" @click="addEle()">+ Add Element</p>
        </div>
      </div>
    </div>
    <div v-if="showAdminDialog" class="popup_overlay">
      <div persistent class="custom_dialog w-96" >
      <div class="bg-white rounded-sm p-4">
        <p class="heading-1 font-bold" primary-title>
          Are You Sure ?
        </p>
        <p class="pt-3 heading-4" >Do you want to make <span class="font-bold text-primary">{{employeeTypeObj.firstName }} {{employeeTypeObj.lastName}}</span> super Admin?</p>
          <div class="text-right pt-4">
            <button
              class=" bg-gray-200 cta-text text-text1 py-1.5 px-5 mr-2 rounded-3xl"
              depressed=""
              rounded
              @click="showAdminDialog = false;employeeTypeObj.isSuperAdmin = false"
            >
              NO
            </button>
            <button class="bg-primary text-white font-bold px-5 py-1.5 rounded-2xl" @click="showAdminDialog = false;employeeTypeObj.isSuperAdmin = true">
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import HRM from '@/View/CMS/api/HRM.js'
// import Dropdown from '@/View/components/dropdown.vue'
export default {
  components: {
    // Dropdown,
  },
  props: ['componentMasterId'],
  data () {
    return {
      selectedStatus: '',
      inputFieldList: [],
      shortedList: [],
      displayBranch:false,
      emailOverlap: null,
      showAdminDialog: false,
      showDeleteBtn: false,
      showPassword: false,
      passwordVal: '',
      userDetailValue: [],
      compNameErr: false,
      errVal: true,
      componentDetail: {
        componentMasterId: 0,
        projectDetailId: parseInt(this.$route.params.projectId),
        componentName: '',
        isActive: true,
        componentFieldList: [
          {
            componentFieldMappingId: 0,
            fieldMasterId: 0,
            fieldName: '',
            fieldDataType: '',
            selFieldname: '',
            isOpenList: false,
            fieldErr: false,
            displayErr: false,
          }
        ],
      },
      submitted: false,
      shiftlist: [],
      selectedShiftId: '',
      Obj2: {},
      Obj: {},
      cardView: false,
      dialog: true,
      employeeTypeList: []
    }
  },
  validations: {
  },
  computed: {
  },
  mounted () {
    console.log('this.$route.params.componentId', this.$route.params)
    console.log('componentMasterId', this.componentMasterId)
    if (this.componentMasterId > 0) {
      this.getComponentDetail()
    }
    this.inputFieldReqItem()
  },
  watch: {
    componentDetail: {
      handler () {
        if (this.componentDetail.componentName !== '') {
          this.compNameErr = false
          this.errVal = false
        }
        for (let index = 0; index < this.componentDetail.componentFieldList.length; index++) {
          let ele = this.componentDetail.componentFieldList[index]
          if (ele.fieldMasterId !== 0 || ele.fieldDataType !== '') {
            ele.fieldErr = false
            this.errVal = false
          }
          if (ele.fieldName !== '') {
            ele.displayErr = false
            this.errVal = false
          }
        }
      },
      deep: true
    }
  },
  methods: {
    focus () {
      console.log('click')
      // let rect = document.getElementById('listID').getBoundingClientRect()
      // console.log('rect', rect.bottom)
      // console.log('window.innerHeight', window.innerHeight)
      // console.log('window.innerHeight', window.innerHeight - rect.bottom)
      // if (window.innerHeight - rect.bottom > 300) {
      //     document.getElementById('listID').style.bottom = `52px`
      // } else {
      //  document.getElementById('listID').style.bottom = "auto"
      // }
    },
    searchValue (value) {
      console.log('value,', value)
      let temp = this.inputFieldList
      if (value === '') {
        this.shortedList = temp
      } else {
        let filter = value.toLowerCase()
        this.shortedList = []
        for (let index = 0; index < temp.length; index++) {
          if (value.toLowerCase().indexOf(filter) > -1 === temp[index].fieldName.toLowerCase().indexOf(filter) > -1) {
           this.shortedList.push(temp[index])
          }
        }
      }
      console.log('value, temp', temp)
    },
    selectStatus (data) {
      console.log('data 1', data)
    },
    selectedStatus1 (data) {
      console.log('data 2', data)
    },
    changeUnderScore (value, from, index) {
      if (from === 'cName') {
        this.componentDetail.componentName = value.replace(/ /g,"_")
      } else if (from === 'dName') {
        this.componentDetail.componentFieldList[index].fieldName = value.replace(/ /g,"_")
      }
    },
    addEle() {
      this.inputFieldReqItem()
      this.componentDetail.componentFieldList.push({
        componentFieldMappingId: 0,
        fieldMasterId: 0,
        fieldName: '',
        fieldDataType: '',
        selFieldname: '',
        isOpenList: false,
      })
    },
    removeItemDetail(index) {
      if (this.componentDetail.componentFieldList.length !== 1) {
        this.componentDetail.componentFieldList.splice(index, 1)
      }
    },
    showPopupBranch(index, data) {
      for (let i = 0; i < this.componentDetail.componentFieldList.length; i++) {
        if (i === index) {
          this.componentDetail.componentFieldList[i].isOpenList = true
        } else {
          this.componentDetail.componentFieldList[i].isOpenList = false
        }
      }
      console.log('this.componentDetail.componentFieldList', data)
      console.log('this.componentDetail.componentFieldList', this.componentDetail.componentFieldList)
    },
    hidePopupBranch() {
      for (let i = 0; i < this.componentDetail.componentFieldList.length; i++) {
        this.componentDetail.componentFieldList[i].isOpenList = false
      }
    },
    selectName(val ,mainIndex, listIndex) {
      console.log('val', val)
      console.log('index', mainIndex, listIndex)
      this.componentDetail.componentFieldList[mainIndex].fieldMasterId = val.fieldMasterId
      this.componentDetail.componentFieldList[mainIndex].fieldDataType = val.fieldDataType
      this.componentDetail.componentFieldList[mainIndex].selFieldname = val.fieldName
      this.componentDetail.componentFieldList[mainIndex].isOpenList = false
    },
    resizeGrid () {
      if (window.innerWidth < 960) {
        this.cardView = true
      } else {
        this.cardView = false
      }
    },
    addComponent () {
        if (this.componentDetail.componentName === '') {
          this.compNameErr = true
          this.errVal = true
        }
        for (let index = 0; index < this.componentDetail.componentFieldList.length; index++) {
          console.log('this.componentDetail.componentFieldList', this.componentDetail.componentFieldList)
          let ele = this.componentDetail.componentFieldList[index]
          if (ele.fieldMasterId === 0 && ele.fieldDataType === '') {
            ele.fieldErr = true
            this.errVal = true
          }
          if (ele.fieldName === '') {
            ele.displayErr = true
            this.errVal = true
          }
        }
        if (this.errVal === false) {
          console.log(this.componentDetail)
          this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
          HRM.addEditComponent(
            this.componentDetail,
            response => {
              // console.log(response)
              this.$root.$emit('closeComponentDialog', false)
              this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            error => {
              // console.log(error)
              this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
    },
    getComponentDetail () {
        console.log('this.componentDetail', this.componentDetail)
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        HRM.getCompDetail(
          this.componentMasterId,
          response => {
            let tempData = response.Data
            console.log('detail api req', tempData)
            this.componentDetail.componentMasterId = JSON.parse(tempData.componentMasterId)
            this.componentDetail.projectDetailId = JSON.parse(tempData.projectDetailId)
            this.componentDetail.componentName = tempData.componentName
            this.componentDetail.isActive = JSON.parse(tempData.isActive)
            if (tempData.componentFieldList !== null) {
              this.componentDetail.componentFieldList = []
              for (let index = 0; index < tempData.componentFieldList.length; index++) {
                let obj = {
                  componentFieldMappingId: tempData.componentFieldList[index].componentFieldMappingId,
                  fieldMasterId: tempData.componentFieldList[index].fieldMasterId,
                  fieldName: tempData.componentFieldList[index].filedName,
                  fieldDataType: tempData.componentFieldList[index].fieldDataType,
                  isOpenList: false,
                  selFieldname: '',
                  fieldErr: '',
                  displayErr: '',
                }
                this.inputFieldList.forEach(element => {
                  console.log('~~~~~~~~~~~~~~~~~~~~~~ 1', element.fieldMasterId)
                  console.log('~~~~~~~~~~~~~~~~~~~~~~ 2', tempData.componentFieldList[index].fieldMasterId)
                  if (element.fieldMasterId === tempData.componentFieldList[index].fieldMasterId) {
                    obj.selFieldname = element.fieldName
                  }
                })
                console.log('obj`````````````````', obj)
                // if (tempData.componentFieldList[index].fieldMasterId === 0 && tempData.componentFieldList[index].fieldDataType === '') {
                //   obj.fieldErr = true
                // } else {
                //   obj.fieldErr = false
                // }
                // if (tempData.componentFieldList[index].fieldName === '') {
                //   obj.displayErr = true
                // } else {
                //   obj.displayErr = false
                // }
                this.componentDetail.componentFieldList.push(obj)
              }
            }
            console.log('this.componentDetail 123', this.componentDetail)
            console.log('response.data', response.Data)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          },
          error => {
            // console.log(error)
            this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
    },
    inputFieldReqItem () {
      HRM.inputFieldListing(
        true,
        response => {
          this.inputFieldList = response.Data
          this.shortedList = response.Data
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
        },
        error => {
          // console.log(error)
          this.$store.dispatch('SetLoader', {status: false, message: 'Please Wait...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    closeDetails () {
      this.$root.$emit('closeComponentDialog', true)
    }
  }
}
</script>
<style scoped>
.profiles {                         
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-item-group,
.v-expansion-panels {
  z-index: 0 !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 0 20px 0 !important;
}
.content {
  padding: 5px 5px;
}
.checkbox {
  color: grey;
  font-size: 14px;
}
.checkbox1 {
  color: grey;
  font-size: 11px;
}
.checkbox2 {
  color: grey;
  font-size: 11px;
}
.buttons .button {
  margin-bottom: 0rem;
}
.input:focus {
  outline: none;
  border-color: 0 0 0 0.125em rgba(31, 82, 190, 0.25);
  box-shadow: none;
}
a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: white;
  color: black;
}
.datetimepicker .datetimepicker-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  position: relative;
  padding: 0.3rem 1rem;
}
.box:not(:last-child),
.content:not(:last-child),
.notification:not(:last-child),
.progress:not(:last-child),
.table:not(:last-child),
.table-container:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.block:not(:last-child),
.highlight:not(:last-child),
.breadcrumb:not(:last-child),
.level:not(:last-child),
.list:not(:last-child),
.message:not(:last-child),
.tabs:not(:last-child) {
  margin-bottom: 0rem;
}
.autocomplete .dropdown-content {
  overflow: auto;
  max-height: 200px;
}
.buttons:last-child {
  margin-bottom: 0rem;
}
</style>
